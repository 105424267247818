<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <rentfix-open-api />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import RentfixOpenApi from '@/components/help-desk/rentfix-open-api';
import ViewSkeleton from '@/components/utils/view-skeleton';

export default {
  components: {
    DefaultLayout,
    ViewSkeleton,
    RentfixOpenApi,
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.corporate'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  async fetch({ store, route }) {
    const baseUrl = store.state.global.baseUrl;
    let title = '';
    let description = '';
    title = 'Open API Rentfix.com - Platform Sewa Properti di Indonesia';
    description =
      'Registrasi untuk mengakses open API Rentfix.com, situs yang membantu Anda melakukan proses sewa properti mulai dari rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di seluruh Indonesia.';
    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
};
</script>
