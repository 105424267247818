<template>
  <div>
    <div>
      {{ $t('home.rentfixOpenApi.content') }}
    </div>
    <div class="d-flex flex-wrap my-4">
      <a
        href="#rentfixOpenApiForm"
        class="btn btn-primary mr-4 mb-2"
        style="text-decoration: none"
        >{{ $t('home.rentfixOpenApi.register') }}</a
      >
      <!--      <a href="#" class="btn btn-primary mb-2" target="_blank" style="text-decoration: none;">{{-->
      <!--        $t('home.rentfixOpenApi.documentation')-->
      <!--      }}</a>-->
    </div>
    <div class="subtitle--text">
      {{ $t('home.rentfixOpenApi.benefit') }}
    </div>
    <div class="d-flex flex-wrap">
      <div class="col-12 col-md-4 benefit--col">
        <v-card>
          <div class="d-flex flex-wrap justify-center">
            <img src="@/assets/img/icons/network.png" />
            <div>
              <span>{{ $t('home.rentfixOpenApi.benefit3_1') }} </span>
              <router-link to="/official-partner">{{
                $t('home.rentfixOpenApi.benefit3_2')
              }}</router-link>
              <span> {{ $t('home.rentfixOpenApi.benefit3_3') }}</span>
            </div>
          </div>
        </v-card>
      </div>
      <div class="col-12 col-md-4 benefit--col">
        <v-card>
          <div class="d-flex flex-wrap justify-center">
            <img src="@/assets/img/icons/jigsaw.png" />
            {{ $t('home.rentfixOpenApi.benefit1') }}
          </div>
        </v-card>
      </div>
      <div class="col-12 col-md-4 benefit--col">
        <v-card>
          <div class="d-flex flex-wrap justify-center">
            <img src="@/assets/img/icons/form.png" />
            {{ $t('home.rentfixOpenApi.benefit2') }}
          </div>
        </v-card>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'RentfixOpenApiContent',
  components: {},
};
</script>
