<template>
  <section class="help--desk-wrapper">
    <div class="title--text">
      {{ $t('home.rentfixOpenApi.title') }}
    </div>
    <rentfix-open-api-content />
    <div class="title--text" id="rentfixOpenApiForm">
      {{ $t('home.rentfixOpenApi.formTitle') }}
    </div>
    <rentfix-open-api-form />
  </section>
</template>

<script>
import RentfixOpenApiContent from '@/components/help-desk/rentfix-open-api/rentfix-open-api-content';
import RentfixOpenApiForm from '@/components/help-desk/rentfix-open-api/rentfix-open-api-form';

export default {
  components: {
    RentfixOpenApiContent,
    RentfixOpenApiForm,
  },
};
</script>
